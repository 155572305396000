<template>
  <div>
    <Members></Members>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Members from '@/components/teams/Members'

export default {
  name: 'TeamManagementDetail',
  mounted() {
    this.setBreadcrumb()

    this.$ml.bus.$on('vueml-language-changed', (payload) => {
      this.setBreadcrumb()
    })
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$ml.get('teamManagementDetail') },
      ])
    },
  },
  components: {
    Members,
  },
}
</script>
