<template>
  <Card class="teams-detail">
    <CardLoader v-if="loading">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>

    <template #header>
      <div class="row w-100 p-0 m-0">
        <div class="title col-md-6">
          <h3 class="text-dark font-size-secondary m-0">
            {{ $ml.get('members') }}
          </h3>
        </div>
        <div class="col-md-6 text-right card-buttons"
             v-if="!loading">
          <b-button variant="danger"
                    size="sm"
                    class="rounded-sm"
                    v-if="items.length > 0 && itemIds.length > 0"
                    @click="deleteSelectedMembers()">{{ $ml.get('deleteSelected') }}
          </b-button>

          <!-- <b-button variant="primary"
                    size="sm"
                    class="rounded-sm ml-2">{{ $ml.get('addNewMembers') }}</b-button> -->
        </div>
      </div>
    </template>

    <template v-if="!loading">
      <v-data-table :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :items-per-page="100"
                    :server-items-length="100"
                    :loading="loading"
                    :hide-default-footer="true"
                    id="members-list">
        <template v-slot:header.choose>
          <label class="checkbox">
            <input type="checkbox"
                   v-model="checked"
                   @change="checkAll" />
            <span></span>
          </label>
        </template>

        <template v-slot:header.action>
          {{$ml.get('action')}}
        </template>

        <template v-slot:item.choose="{ item }">
          <label class="checkbox">
            <input type="checkbox"
                   v-model="itemIds"
                   :value="item.id" />
            <span></span>
          </label>
        </template>

        <template v-slot:item.action="{ item }"
                  class="p-0 actionsButton">
          <!-- <ButtonEdit class="mr-2"
                      :class="!item.is_valid ? 'cursor-not-change' : ''"></ButtonEdit> -->
          <ButtonDelete @click.native="deleteMember(item)"></ButtonDelete>
        </template>
      </v-data-table>
    </template>

  </Card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardLoader from '@/view/content/CardLoader'
import Card from '@/components/Card'
import ButtonEdit from '@/components/buttons/ButtonEdit'
import ButtonDelete from '@/components/buttons/ButtonDelete'

export default {
  name: 'Members',
  data() {
    return {
      loading: false,
      checked: false,
      itemIds: [],
      items: [],
      options: {},
      selectedItems: '',
      headers: [
        { text: '', value: 'choose', sortable: false, width: '40px' },
        {
          text: this.$ml.get('fullname'),
          value: 'fullname',
          sortable: false,
        },
        {
          text: this.$ml.get('emailAddress'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$ml.get('actions'),
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ],
      itemIdsProcessCount: 0,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.getMembersData()
        .then((res) => {
          this.setMembersData(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getMembersData() {
      this.loading = true
      return new Promise((resolve, reject) => {
        let url = `teams/${this.$route.params.id}/members?position=created_at|DESC`

        ApiService.get(url)
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setMembersData(res) {
      this.items = res.data
    },
    checkAll() {
      this.itemIds = []
      if (this.checked) {
        for (const i in this.items) {
          this.itemIds.push(this.items[i].id)
        }
      }
    },
    deleteMember(members) {
      this.$bvModal
        .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedMembers'), {
          title: this.$ml.get('pleaseConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$ml.get('yes'),
          cancelTitle: this.$ml.get('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true
            ApiService.delete(
              `teams/${this.$route.params.id}/members/${members.id}`
            )
              .then(() => {
                this.getData()
                this.loading = false
                this.generateToast(
                  'success',
                  this.$ml.get('deletedMemberSuccess')
                )
              })
              .catch((err) => {
                this.loading = false

                if (err == 'Error') {
                  this.generateToast(
                    'danger',
                    this.$ml.get('anErrorHasOccurred')
                  )
                }
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteSelectedMembers() {
      if (this.itemIds.length === 0) {
        this.generateToast('danger', this.$ml.get('noSelection'))
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedMembers'), {
            title: this.$ml.get('pleaseConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$ml.get('yes'),
            cancelTitle: this.$ml.get('no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true
              for (const i in this.itemIds) {
                ApiService.delete(
                  `teams/${this.$route.params.id}/members/${this.itemIds[i]}`
                )
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    this.itemIdsProcessCount++
                  })
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  watch: {
    itemIdsProcessCount(value) {
      if (this.itemIds.length == value) {
        this.itemIdsProcessCount = 0
        this.loading = false
        this.getData()
      }
    },
    itemIds() {
      if (this.itemIds.length == this.items.length) this.checked = true
      else this.checked = false
    },
  },
  components: {
    Card,
    ButtonEdit,
    ButtonDelete,
    CardLoader,
  },
}
</script>